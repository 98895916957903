@font-face {
    font-family: 'Phantom';
    src: url('./font/PhantomVarV0.8_VF.woff2') format('woff2 supports variations'),
        url('./font/PhantomVarV0.8_VF.woff2') format('woff2-variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
    font-display: fallback;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
button,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}
blockquote,
q {
    quotes: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    background-color: white;
    color: #4d4d4d;
    /* These get overriden by js */
    font-family: Phantom, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

html {
    scroll-behavior: smooth;
}

body[aria-hidden='true'] {
    overflow: hidden;
}

body.ReactModal__Body--open {
    overflow: hidden;
}

ul,
ol {
    list-style-type: none;
}

hr {
    border: 2px solid #ebebeb;
    margin: 2rem 0;
}

a {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: none;
}

strong {
    font-weight: 700;
}

sup {
    vertical-align: super;
    font-size: 0.7em;
}

button {
    text-align: left;
}

img,
video {
    display: block;
}

#hubspot-messages-iframe-container {
    z-index: 109 !important;
}
